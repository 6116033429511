header.slds-global-header_container{
    width: 100%;
    z-index: 2;
    position: static;
    padding-top: 0;
    background:transparent;
    box-shadow: none;
}
// #wrapper{
//     padding:0 10px;
// }
// footer{
//     width:99%;
// }
.slds-page-header{
    margin:15px 0 0 0;
    box-shadow: none;
    border-color:#dcdcdc!important;
}
.slds-context-bar__item.slds-is-active:before{
    top:inherit;
    background:#372a71;
}
header.slds-popover__header {
    background: transparent;
    box-shadow: none !important;
    padding:0.5rem 0.75rem !important;
  }
#header-profile-custom-popover-content a {
    color: #006dcc;
    text-decoration: none;
    transition: color 0.1s linear;
}
.slds-theme_shade.slds-p-left_small {
    color: #1e1e1e;
}
div#header-profile-popover-dialog-body {
    padding: 0;
}
header.slds-global-header_container div.slds-global-header {
    background-color: #f6f6f6;
}
.slds-context-bar{
    border-bottom:none;
    box-shadow:0 2px 4px rgba(0, 0, 0, 0.07);
}
.slds-context-bar__item{
    border-bottom: 2px solid transparent;
    margin: 0 35px 0 0;
    line-height: 40px;
}
.slds-context-bar__item:hover{
    background:transparent!important;
}
.mydetails .slds-media__body{
    margin: 8px 0 0 0;
}
.mydetails .slds-truncate{
    font-size:16px;
}
.portalname .slds-context-bar__app-name{
    padding:0;
    cursor: auto;
}
// .detailspage .slds-box{
//     border:none !important;
//     box-shadow:2px 2px 7px rgba(0, 0, 0, 0.1);
// }
.slds-modal__header{
    padding:1rem !important;
}
.detailspage  .slds-form-element__label{
    color:#3e3e3c;
    font-size: 0.75rem;
    font-family:"Salesforce Sans", Arial, Helvetica, sans-serif;
}
.detailspage .slds-form-element__control{
    color: #1e1e1e;
    font-size: 13px;
    font-family:"Salesforce Sans", Arial, Helvetica, sans-serif;
}
.detailspage .slds-tabs_default__link, 
.detailspage .slds-tabs--default__link{
    color: #4d5145;
}
.mb-10{
    margin-bottom: 10px;
}
.slds-form-element__label{
    color:#999d91;
    font-size: 14px;
}
.detailspage .slds-tabs_default__nav, 
.detailspage .slds-tabs--default__nav{
    border-color:#dcdcdc!important;
}
.ReactModalPortal footer{
    width:100%;
    background: #eef2e6;
}
.slds-context-bar__item:hover, 
.slds-context-bar__item.slds-is-active {
    border-radius: 0;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    border-bottom: none;
    animation: none;
}
.slds-context-bar__item:hover:after{
    background: transparent !important;
}

.mydetails{
    ul{
        li{
            width:33%;
            .slds-text-title{
                width:auto;
                display: inline-block;
                margin-right:10px;
                font-weight:bold;
                font-size: 14px;
            }
            .fields_inputs{
                display: inline-block;
                a{
                    color:#000 !important;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    .mydetails{
        ul{
            display: block;
            li{
                width: 100%;
                display: block;
                max-width: 100% !important;
                padding-right: 0;
                text-align: left;
                padding-left: 0;
                margin: 0 0 10px 0;
            }
        }       
    }
    .slds-context-bar__item {
        border-bottom:none;
        margin: 0 15px 0 0;
        line-height: 40px;
    }
    .slds-context-bar{
        padding-left:15px;
    }
    #SidebarTogglerParent{
        display:block;
    }
    .global-menu-mob .portalname .slds-context-bar__app-name{
        padding:10px 15px !important;
    }
    .global-menu-mob .portalname .slds-context-bar__app-name span{
        color: #1589ee;
        //   text-transform: uppercase;
        padding: 6px 2px;
    }
}
@media (min-width: 769px) {
    #SidebarTogglerParent{
        display:none;
    }
}
span.slds-page-header__title.slds-truncate {
    font-size: 18px !important;
    font-weight: bold !important;
}
.slds-global-header{
    box-shadow: none !important;
  }
  header{
   padding: 0px  !important;
  }


  .slds_my_card .slds-card {
    position: relative;
    padding: 0;
    background: #fff;
    border: 1px solid #dddbda !important;
    border-radius: .25rem;
    background-clip: padding-box;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.1) !important;
}

.max-width-res {
    max-width: 100% !important;
}


@media only screen and (max-width: 600px) {
    .slds-datepicker.date_picker {
        padding: 0 !important;
        max-width: 100% !important;
        right: 0 !important;
        left: inherit !important;
    }
    .slds-datepicker .calendar thead > tr > th,
    .slds-datepicker .calendar tbody > tr > td{
        padding:0 !important
    }
    .slds-combobox__form-element.slds-input-has-icon.slds-input-has-icon_right + div ul{
        max-width:80px;
        width:80px !important;
    }
}