// make this styling scoped to avoid affecting other styles
.slds-related-tab-details {
    font-family: "Salesforce Sans", Arial, Helvetica, sans-serif;
    font-size: 13;
    
    .slds-card-bor {
        border: 1px solid #dddbda !important;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,.1) !important;
    }
    
    .slds-box-bg {
        background: #fff;
    }
    
    .sdls-timeline-subject {
        font-size: 13px;
    }

    .SLDSReactTable.ReactTable .rt-noData {
        vertical-align: middle;
        background-color: white;
        position: static;
        width: 100%;
        transform: none;
        margin: 0;
        line-height: 26px;
    }
    
    .radio-btn-display {
        display: inline-block !important;
    }

    .reset-col {
        color: #0070d2;
    }
}

.slds-header-document-mob-view {
    .slds-page-header__col-title {
        float: left;
        flex: none;
    }
}

.slds-tbl-card-td-link-hidden {
    overflow:hidden !important;
}

.slds-tbl-card .slds-more-action-dropdown.slds-dropdown,
 .slds-tbl-card .slds-more-action-dropdown.slds-dropdown {
  display: block;
  visibility: visible;
  opacity: 1;
  //   left: 0 !important;
  left:inherit !important;
  top: 100% !important;
  max-width: 150px !important;
  width: 100%;
  overflow: auto;
  //   right: inherit !important;
  right:0 !important;
  min-width: 150px !important;
  top: 100% !important;
}
.slds-tbl-card .rt-tbody,
.slds-tbl-card.rt-table{
  overflow: visible;
}
.slds-tbl-card-td-dd .rt-th, .slds-tbl-card-td-dd {
  overflow:visible !important;
}

.SLDSISODatePicker {
    .slds-dropdown-trigger {
        width: 100%
    }
}

.bor-top-1 {
    border-top: 1px solid #ccc;
}

.slds-col-wid {
    width: 1% !important;
}

section.manage_top .absolute-positioned.slds-datepicker {
    top: 100% !important;
}

.slds-header-document-mob-view {
    margin: 0;
    box-shadow: none;
    border-radius: 0px;

    .slds-media__body {
        margin: 0.3rem 0 0 0;
    }
    .slds-page-header__row {
        flex-wrap: wrap;
    }

    .back_link .slds-button {
        margin: 0.5rem 0 0 0;
    }
}

.slds-header-document-detail-mob-view {
    margin: 0;
    box-shadow: none;
    border-radius: 0px;

    .slds-media__body {
        margin: 0.3rem 0 0 0;
    }
}

.slds-more-action-dropdown {
    max-width: 10rem !important;
    width: 100%;
    min-width: 10rem !important;
}

.slds-button.slds-button_icon-border-filled {
    /*width: 1.5rem;
    height: 1.5rem;*/
}

.slds-box-mob-view {
    padding: 0.5rem !important;
    border-radius: 0.5rem !important;
}

.slds-tbl-scroll .slds-tbl-card .slds-more-action-dropdown.slds-dropdown,
.slds-tbl-scroll .slds-tbl-card .slds-more-action-dropdown.slds-dropdown{
    left:inherit !important;
    right:0 !important;
}

.slds-header-document-detail-web-view {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.slds-con-max-w { 
    max-width: 100%;
}
.dismax-wid{
    display: inline-block;
    max-width: 90% !important;
}
@media (max-width: 768px) {
    .ShiftDetails .slds-page-header__detail-row {
        display: block;
    }
    .ShiftDetails .slds-page-header__detail-block {
        flex: none;
        max-width:100%;
        margin: 10px 0 0 0;
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media (max-width: 480px) {
    .ShiftDetails .slds-page-header__row {
        display: block;
    }
    .ShiftDetails .slds-page-header__col-actions {
        width: 100%;
        margin: 10px 0 0 0;
        position: relative;
    }
    .ShiftDetails .slds-page-header__col-title {
        flex: none;
        max-width:100%;
        margin: 10px 0 0 0;
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
    }
    .slds-page-header__col-actions.slds-align-middle.slds-p-bottom_none {
        width: 100%;
        margin: 10px 0 0 0;
    }
    .slds-page-header__col-title {
        flex: none;
    }
    .slds-page-header__col-meta {
        flex: none;
    }
    .slds-tile-lbl {
        width: 60% !important;
    }
    .slds-tile-val {
        width: 40% !important;
    }
}

@media (max-width: 767.98px) {
    .member-modal-mob-view {
        .slds-modal__footer {
            .slds-button {
                width: 45%;
            }
            .save-btn {
                width: 50% !important;
            }
        }
    }
    .slds-tile-lbl {
        width: 60% !important;
    }
    .slds-tile-val {
        width: 40% !important;
    }
}

@media (max-width: 768px) {
    .slds-tile-lbl {
        width: 60% !important;
    }
    .slds-tile-val {
        width: 40% !important;
    }
}

@media (max-width: 976.98px) {
    .slds-tbl-card.rt-table{
        overflow:auto !important;
    }

    .slds-tbl-scroll {
        overflow: hidden !important;
        overflow-x: auto !important;
    }
}

@media (min-width: 768px) {
    .slds-tile-lbl {
        width: 60% !important;
    }
    .slds-tile-val {
        width: 40% !important;
    }
}

.padrow {
    padding: 15px 0px 0px 0px;
}