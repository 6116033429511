
.SLDSPath {
    .slds-path__nav .slds-is-lost .slds-path__link, 
    .slds-path__nav .slds-is-won .slds-path__link {
        color: white;
    }


}
.slds {
    .slds-timeline a {
        color: #006dcc;
        font-size: 13px;
        
        &:hover {
            text-decoration: underline !important;
        }
    }
}
.select_options_des div.SLDSReactSelect.Select {
    width:40%;
}
.dia_form_siz{
    margin:10px 0;
    padding:0;
}
.dia_form_siz label{
    font-size:12px;
    font-weight:600;
}
.dia_form_siz span{
    font-size:12px;
}
body .ml-0{
    margin-left:0 !important;
}
.shiftbtns_list .slds-button_neutral{
    padding:0 12px !important;
    white-space: nowrap;
}

.moreli {
    .slds-is-active-crm {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
    .slds-is-active-recruitment {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
    .slds-is-active-imail {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
    .slds-is-active-admin {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
    .slds-is-active-item {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
    .slds-is-active-finance {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
    .slds-is-active-participant {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
    .slds-is-active-schedule {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
    .slds-is-active-fms {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
    .slds-is-active-organisation {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
    .slds-is-active-member {
        border-top: 0px !important;
        animation: bkAnim .135s cubic-bezier(.39,.575,.565,1) both
    }
}

.extramoremenus{
    float: left;
    display: block;
    right: -100px;
    position: absolute;
    width: 200px !important;
    background: #fff;
    border: 1px solid #f6f7f7;
    padding: 5px 0;
    box-shadow: 0 1px 3px 0px #a69f9f;
    z-index: 1;
  }
  .extramoremenus .slds-context-bar__label-action{
    padding:6px 10px;
  }

//   .extramoremenus {
//     transform: rotate(180deg);
// }
// .extramoremenus > li {
//     transform: rotate(-180deg);
// }
